<template>
	<prac-test :test=test></prac-test>
</template>
<script>
import { PracTest } from "@/components";

export default {
  name: "practice-tests",
  components: {
    PracTest
  },
  data() {
    return {
      test: {
        date: "September 2018",
        title: "Scaling Teams",
        subtitle: "Strategies for Building Successful Teams and Organizations",
        description:
          "Online flashcards based on the book Scaling Teams by David Loftesness and Alexander Grosse",
        bg: "/img/bg24.jpg",
        author: "David Loftesness and Alexander Grosse",
        references:
          "Scaling Teams by David Loftesness and Alexander Grosse (2017, O'REILLY)",
        recommendation:
          "The book 'Scaling Teams' is for everybody involved in growing companies who would rather make use of proven solutions from successful companies instead of trying to respond to scaling problems.",
        items: [
          {
            front:
              "At what stage do leaders often fail to shift their focus from building the right product to building the right team?",
            back:
              "Once the team finds product-market fit and starts to shift into growth mode."
          },
          {
            front:
              "What are the growing pains that rapidly growing start-ups often experience?",
            back:
              "Worse productivity, unhappy customers and problems with communications."
          },
          {
            front:
              'How can the employees of a start-up be negatively be effected by the growing pains brought about by "hyper-growth"?',
            back: "Morale problems and disgruntled employees."
          },
          {
            front:
              "What are alternatives to focus on scaling teams and are they preferable?",
            back:
              "<ul><li>Because of the risks of scaling teams, the alternatives are often preferable and can reduce he amount of new hires.</li><li>Alternatives are process improvements, canceling unnecessary projects and organizational changes.</li></ul>"
          },
          {
            front:
              "What is the source of many scaling crises in hyper-growth teams?",
            back:
              "Failing to make the mental shift from product to team pmce the teams shifts into growth mode."
          },
          {
            front:
              "What is the main difference between a 'Scaling Plan' and a 'Product Plan'?",
            back:
              "The product plan is focused on the needs of the team rather than the product."
          },
          {
            front: 'Why should you regularly review the "Scaling Plan"?',
            back:
              "To have a backstop that will catch emerging scaling problems before hey become crises."
          },
          {
            front: "<h4>Why is a rigorous interview process important?</h4>",
            back:
              "To reject a candidate is much cheaper than dealing with bad employees."
          },
          {
            front: "What are the axioms of organizational design good for?",
            back2:
              "(a) To prevent 'diseconomies of scale' which occurs when a business grows so large that the costs per unit increases. (b) To make sure that employees are efficient.",
            back:
              "<ul><li>To prevent 'diseconomies of scale' which occurs when a business grows so large that the costs per unit increases.</li><li>To make sure that employees are efficient. \n</li></ul>"
          },
          {
            front:
              "Which design principles can help to introduce structure into a growing organization?",
            back:
              "<ul><li>Have devlivery teams as early as possible.</li><li>Delivery teams are self-sufficient teams with few dependencies on other teams).</li><li>Furthermore, the delivery team should have a clear defined purpose.</li><li>Continuous Delivery</li></ul>"
          },
          {
            front: "Which are the five axioms of organizational design?",
            back2:
              "Make communication effective through delivery teams. (b) Avoid micromanagement by providing autonomy. (c) Have a common purpose and vision in your company. (d) Offer learning opportunities. Improving your skillset can is a great motivator. (e) Continuous delivery - Have a working product that gets better in increments.",
            back:
              "<ul><li>Make communication effective through delivery teams.</li><li>Avoid micomanagement by providing autonomy.</li><li>Have a common purpose and vision in your company. </li><li>Offer learning opportunities. Improving your skillset can is a great motivator.  \n</li><li>Continuous delivery - Have a working product that gets better in increments.</li></ul>"
          },
          {
            front: 'What are "Delivery Teams"?',
            back:
              "When the VP of Engineering is not involved in the team's day-to-day work."
          },
          {
            front:
              "Should you let individual contributors (engineers)  have influence over the roadmap?",
            back:
              '<ul><li>Yes, because individual contributors often have a greater base of knowledge about the product and technology in use at the company.</li><li>"Providing ICs with an explicit mechanism to influence the roadmap ensures that they too can contribute in a predictable and useful way".</li></ul>'
          },
          {
            front:
              "<h4>What are some of the organizational design principles of a scaling teams?</h4>",
            back:
              "<ul><li>Keep it leightweight.</li><li>Each team member should know they contribute to the company's success. </li><li>Teams sould have some level of autonomy. </li></ul>"
          },
          {
            front: "<h4>What are <b>Scaling Plans</b> and do you need them?</h4>",
            back:
              'Scaling plans try to help a team shift a team that is focused on product market fit to growth mode. "After all, it is the team that will ultimately build the product.'
          },
          {
            front:
              "What are the primary <i>scaling-up challenges</i> that a scaling plan should be based on?",
            back:
              'Hiring", "People management", "Organization": everybody should know the goals, "Culture" and "Communications".'
          },
          {
            front: "<h4>Why is rapid growth the enemy of team culture?</h4>",
            back:
              "<ul><li>New employees may not share and understand the team's core values.</li><li>The core values of new hires may diverge.</li><li>Culture clash and culture divergence.</li></ul>"
          },
          {
            front:
              "What are some warning signs of early-stage (to roughly 50 people) scaling problems?",
            back:
              "<ul><li>A disorganized recruiting process.</li><li>Early employeed feel that the talent level is dropping. Or new hires resign after only a few weeks. </li><li>Complains about too many meetings or too much email.</li><li>New employees don't understand what other teams are doing.</li><li>Small meetings are frequently canceled.</li><li>It takes longer to release new features even though the company is growing.\n</li><li>etc.</li></ul>"
          },
          {
            front:
              "What are some warning signs of later-stage (50 - 150 employees)?",
            back:
              "<ul><li>Negative feedback from candidates about th hiring process.</li><li>When candidates often reject the job offers.</li><li>More bugs, customer complains and dowtimes.</li><li>Finger pointing in teams when something goes wrong.</li><li>Poor morale.</li><li>Too many meetings.</li><li>When team mmebers don't know the actual users perspective.</li><li>Solving the same problem again and again.</li><li>Moral of early employees <span>diminishes.</span><div></div></li><li><span>Lower attendance in meetings.</span></li><li><span>etc.</span></li></ul>"
          },
          {
            front:
              "On what should <i>Hyper-growth teams</i> focusing on building the best products change their focus to?",
            back:
              "Hyper-growth teams should change their focus to building the best teams instead of the best products."
          },
          {
            front: "Why should you hold retrospectives?",
            back: "To study and learn from what your team did poorly and well."
          },
          {
            front: "What is the most important quality of a (first) recruiter?",
            back:
              "Emotional Intelligence — your recruiter should have the skill of understanding people."
          },
          {
            front:
              "Hiring: What characteristics should an individual contributor have?",
            back:
              "Mentorship, communication, organic leadership, humility and strategic thinking."
          },
          {
            front: "What should an engineer do when he becomes a manager?",
            back: "Read business books."
          },
          {
            front:
              "Through which ways should you seek new people to bring on board?",
            back:
              "Seek the right candidates through friends, applications and online platforms."
          },
          {
            front:
              "What is a problem to the default solution to the challenges of expansion by hiring more people?",
            back:
              "Often, managers believe that they can fix their problems by hiring more individual contributors. However, this often complicates the problem of rapid scaling often even more."
          },
          {
            front: "What are five common <i>scaling-up issues</i>?",
            back2:
              "(a) Hiring: make sure that you have a scalable hiring process; (b) People Management: Make sure that you hire the right people and that you keep them motivated through interesting assignments and possible career advancement. (c) Organization: make sure that your way the tem members report to the manager is effective. (d) Culture: No matter how many new people you hire, your values and culture have to be cultivated. (e) To avoid conflicts, you should ensure that people know the comapny's culture. Communication: In a small company, it's customary that everybody knows what is going on and has a relationship of trust. When your firm grows, you have to focus on 'scaling communications'.",
            back:
              "<ul><li>Hiring: make sure that you have a scalable hiring process</li><li>People Management: Make sure that you hire the right people and that you keep them motivated through interesting assignments and possible career advancement.</li><li>Organization: make sure that your way the tem members report to the manager is effective.</li><li>Culture:<ul><li>No matter how many new people you hire, your values and culture have to be cultivated.</li><li>To avoid conflicts, you should ensure that people know the comapny's culture.</li></ul></li><li>Communication: In a small company, it'scustomary thateverybody knows what is going on and has a relationship of trust. When your firm grows, you have to focus on \"scaling communications\".</li></ul>"
          },
          {
            front:
              "What is considered the most effective method for finding new candidates?",
            back:
              "Through referrals which are friends of the company such as advisors and board members."
          },
          {
            front:
              "What are the factors that determine whether it's the right time to hire an internal recruiter?",
            back2:
              "(a) Depends on how much time recruitment work takes and what your recruitment strategy is. (b) You can calculate the time it takes for a hiring manager to be successful by assuming that a full-time recruiter typically hires one to two candidates each month.",
            back:
              "<ul><li>Depends on how much time recruitment work takes and what your recruitment strategy is.</li><li>You can calculate the time it takes for a hiring manager to be successful by assuming that a full-time recruiter typically hires one to two candidates each month.<br></li></ul>"
          },
          {
            front: "What are reasons for a company to build an Employer Brand?",
            back2:
              '(a) "A strong brand can help you tremendously with recruiting [16]". (b) It can boost retention: you can for example give your employees the opportunity to promote their work through conference appearance, Open Source contributions or meetups to connect to local candidates.',
            back:
              '<ul><li>"A strong brand can help you tremendously with recruiting [16]".</li><li>It can boost retention: you can for example give your employees the opportunity to promote their work through conference appearance, Open Source contributions or meetups to connect to local candidates.</li></ul>'
          },
          {
            front:
              "Can it be helpful to have more than four interviews for better <i>screening job candidates</i>?",
            back:
              "No, research from Google found that after the fourth interview, each additional interview was not helpful."
          },
          {
            front:
              "What is a possible action you can take when your recruiting process is disorganized?",
            back:
              "Assign one employee to be responsible for the recruiting process and organize a training for all employees involved in interviewing. [243]"
          }
        ]
      }
    };
  }
};
</script>
